<template>
  <div>
    <b-card class="cs-relative cs-print">
      <div class="d-print-none">
        <div class="d-print-none d-flex justify-content-end mr-4 mb-3">
          <b-button @click="handlePrint" variant="primary">In phiếu</b-button>
        </div>
        <span @click="handleClose" class="cs-close">&times;</span>
      </div>
      <div class="container cs-container">
        <div ref="documentPrint" id="documentPrint" class="h-full">
          <div class="row">
            <div class="col-3">
              <!-- <div class="text-uppercase">Sở y tế tỉnh Thái Bình</div>
              <div>Bệnh viện K Tân Triều</div>-->
            </div>
            <div class="col-6 text-center font-weight-bold">
              <h3 class="text-uppercase">Cộng hoà xã hội chủ nghĩa Việt Nam</h3>
              <h5>Độc lập - Tự do - Hạnh phúc</h5>
            </div>
            <div class="col-3 d-flex justify-content-end cs-text-bold">
              <span>Số:</span>
              <span class="ml-1">{{ receivingData.receiving_code}}</span>
            </div>
          </div>
          <div class="text-center mt-2">
            <h2 class="text-uppercase">Phiếu nhập</h2>
            <h4
              v-if="receivingData.inventory_info"
              class="m-0"
            >{{ receivingData.inventory_info.name }}</h4>
            <span>
              Ngày nhập:
              <i>{{ formatDateTime(receivingData.date_add)}}</i>
            </span>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <span>Họ tên người nhận hàng:</span>
              <span class="ml-1 cs-text-bold">{{ receivingData.receiver_name }}</span>
            </div>
            <div class="col-3">
              <span>Số HĐ:</span>
              <span class="ml-1 cs-text-bold">{{ receivingData.invoice_code }}</span>
            </div>
            <div class="col-3">
              <span>Ngày HĐ:</span>
              <span class="ml-1 cs-text-bold">{{ formatDate(receivingData.day_contract) }}</span>
            </div>
          </div>
          <div class>
            <span>Nhà cung cấp:</span>
            <span class="ml-1 cs-text-bold">{{ getSupplierLabel(receivingData) }}</span>
          </div>
          <b-overlay
            :show="false"
            rounded="sm"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
          >
            <b-table
              small
              striped
              bordered
              outlined
              class="cs-btable mt-2"
              responsive
              :items="medicines"
              :fields="tableColumns"
            >
              <template #cell(index)="data">
                <span class="text-capitalize">{{ data.index+1 }}</span>
              </template>
              <template #cell(unit_id)="data">
                <span class="text-capitalize">{{getUnit(data.item) }}</span>
              </template>
              <template #cell(country)="data">
                <span class="text-capitalize">{{getCountryLabel(data.item) }}</span>
              </template>
              <template #cell(expired_date)="data">
                <span class="text-capitalize">{{formatExpiredDate(data.item.product_info) }}</span>
              </template>
              <template #cell(price)="data">
                <span class="text-capitalize">{{appUtils.numberFormat(data.item.realPrice) }}</span>
              </template>
              <template #cell(total_price)="data">
                <span class="text-capitalize">{{appUtils.numberFormat(data.item.total_price) }}</span>
              </template>
              <template #bottom-row>
                <b-th colspan="8" class="text-right" style="font-weight:500">Tổng tiền</b-th>
                <b-th
                  class="text-right"
                  style="font-weight:500"
                >{{ appUtils.numberFormat(receivingData.total_price) }}</b-th>
              </template>
            </b-table>
          </b-overlay>
          <!-- <div class="cs-table">
            <div class="table-header cs-border">
              <div class="cs-text-bold d-flex">
                <div class="w-6 border-r">STT</div>
                <div class="w-18 border-r">Tên thuốc, vật tư</div>
                <div class="w-10 border-r">Đơn vị</div>
                <div class="w-12 border-r">Nơi sản xuất</div>
                <div class="w-8 border-r">Số lô</div>
                <div class="w-15 border-r">Hạn dùng</div>
                <div class="w-10 border-r">Số lượng</div>
                <div class="w-16 border-r">Đơn giá(đã VAT)</div>
                <div class="w-15">Thành tiền</div>
              </div>
            </div>
            <div class="table-body">
              <div
                v-for="(item,index) in medicines"
                :key="index"
                class="d-flex table-item cs-border"
              >
                <div class="w-6 body-item border-r">{{index+1}}</div>
                <div class="w-18 body-item border-r">{{ item.product_info.name }}</div>
                <div class="w-10 body-item border-r">{{ getUnit(item.product_info.unit_id) }}</div>
                <div
                  class="w-12 body-item border-r"
                >{{ getCountryLabel(item.product_info.country) }}</div>
                <div class="w-8 body-item border-r">{{ item.product_info.lot_number }}</div>
                <div class="w-15 body-item border-r">{{ formatExpiredDate(item.product_info) }}</div>
                <div class="w-10 body-item border-r">{{ item.qty }}</div>
                <div class="w-16 body-item border-r">{{ appUtils.numberFormat(item.realPrice) }}</div>
                <div class="w-15 body-item">{{appUtils.numberFormat(item.total_price) }}</div>
              </div>
              <div class="d-flex align-items-center border-b border-r border-l">
                <div
                  class="d-flex justify-content-end border-r"
                  style="flex:1;padding:8px"
                >Tổng tiền</div>
                <div
                  class="w-13"
                  style="margin-left:6px"
                >{{ appUtils.numberFormat(receivingData.total_price) }}</div>
              </div>
            </div>
          </div>-->
          <div class="cs-text-bold mt-3">
            <div>
              <span>- Tổng tiền:</span>
              <span
                v-if="receivingData.total_price"
                class="ml-1"
              >{{ formatPrice(receivingData.total_price) }}</span>
            </div>
            <div class="d-flex align-items-center">
              <span>- Tổng tiền bằng chữ:</span>
              <span v-if="receivingData.total_price" class="ml-1 cs-capitalize">
                <p class="m-0">{{ `${appUtils.numberToWords((receivingData.total_price))}` }}</p>
              </span>
            </div>
          </div>
          <div class="row mt-3 mb-4">
            <div class="col-3 text-center">
              <div>
                <div class="cs-text-bold">Người lập phiếu</div>
                <div>
                  <i>(Ký, họ tên)</i>
                </div>
                <div
                  v-if="receivingData.create_by_info"
                  style="margin-top:75px"
                  class="text-uppercase"
                >{{ receivingData.create_by_info.name}}</div>
              </div>
            </div>
            <div class="col-3 text-center">
              <div>
                <div class="cs-text-bold">Người nhận</div>
                <div>
                  <i>(Ký, họ tên)</i>
                </div>
                <div
                  v-if="receivingData.receiver_name"
                  style="margin-top:75px"
                  class="text-uppercase"
                >{{ receivingData.receiver_name}}</div>
              </div>
            </div>
            <div class="col-3 text-center">
              <div>
                <div class="cs-text-bold">Kế toán</div>
                <div class="text-center">
                  <i>(Ký, họ tên)</i>
                </div>
              </div>
            </div>
            <div class="col-3 text-center">
              <div>
                <div class="cs-text-bold">Phụ trách chuyên môn</div>
                <div class="text-center">
                  <i>(Ký, họ tên)</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";

const tableColumns = [
  { key: "index", label: "STT", sortable: false },
  {
    key: "product_info.name",
    label: "Tên thuốc, vật tư",
    sortable: false
  },
  { key: "unit_id", label: "Đơn vị", sortable: false },
  { key: "country", label: "Nơi sản xuất", sortable: false },
  { key: "lot_number", label: "Số lô", sortable: false },
  { key: "expired_date", label: "Hạn dùng", sortable: false },
  { key: "qty", label: "Số lượng", sortable: false, class: "text-center" },
  {
    key: "price",
    label: "Đơn giá (đã VAT)",
    sortable: false,
    class: "text-right"
  },
  {
    key: "total_price",
    label: "Thành tiền",
    sortable: false,
    class: "text-right"
  }
];

export default {
  name: "ReceivingDetail",
  data() {
    return {
      medicines: [],
      receivingData: {},
      appUtils,
      tableColumns
    };
  },
  watch: {
    $route() {
      this.handleViewDetail();
    }
  },
  created() {
    this.handleViewDetail();
  },
  methods: {
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    handlePrint() {
      window.print();
    },
    handleClose() {
      this.$router.replace({ name: "BillManager", query: { tab: 0 } });
    },
    async handleViewDetail() {
      try {
        const response = await this.$store.dispatch(
          "receiving/getReceivingDetail",
          { id: this.$route.params.id }
        );

        this.medicines = (response.data?.receiving_detail || [])?.map(item => {
          const realPrice =
            (item?.input_price || 0) * (1 + item?.product_info?.vat_in / 100);
          const roundPrice = Math.round((realPrice * 100) / 100);
          return {
            ...item,
            realPrice: roundPrice,
            total_price: (roundPrice || 0) * (item?.qty || 0)
          };
        });

        const totalPrice = this.medicines?.reduce(
          (total, item) => total + (item?.total_price || 0),
          0
        );
        this.receivingData =
          { ...response.data, total_price: totalPrice } || {};
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    getTotalPriceItem(item) {
      return appUtils.numberFormat((item?.input_price || 0) * (item?.qty || 0));
    },
    getUnit(data) {
      return data?.product_info?.unit_info?.name || "";
    },
    getCountryLabel(data) {
      return data?.product_info?.country_info?.name || "";
    },
    formatExpiredDate(data) {
      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    },
    formatDate(timestamp) {
      return window.moment(timestamp).format("DD/MM/YYYY");
    },
    formatDateTime(timestamp) {
      return window.moment(timestamp).format("HH:mm DD/MM/YYYY");
    },
    getSupplierLabel(data) {
      return data?.supplier_info?.supplier_name;
    }
  }
};
</script>

<style lang="scss" scoped>
.cs-container {
  position: relative;
  min-height: calc(100vh - 31px);
  max-width: 1000px;
  background-color: #fff !important;
}

.cs-container * {
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;

  h2 {
    font-weight: 600;
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .cs-text-bold {
    font-weight: 600;
  }

  .cs-table {
    // width: 1000px;
    margin-top: 16px;
    .w-6 {
      width: 6%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-15 {
      width: 15%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-8 {
      width: 8%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-10 {
      width: 10%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-12 {
      width: 12%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-13 {
      width: 13%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-16 {
      width: 16%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-18 {
      width: 18%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-20 {
      width: 20%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-90 {
      width: 85%;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .table-item {
      border-top: unset;
    }

    .body-item {
      padding-top: 8px;
      padding-bottom: 8px;
      // border-right: 1px solid #787878;

      box-sizing: border-box;
    }
  }
  .cs-table * {
    font-size: 16px;
  }

  .border-t {
    border-top: 1px solid #787878;
  }
  .border-b {
    border-bottom: 1px solid #787878;
  }
  .border-l {
    border-left: 1px solid #787878;
  }
  .border-r {
    border-right: 1px solid #787878;
  }
  .cs-border {
    border: 1px solid #787878;
  }
  .cs-capitalize {
    text-transform: lowercase;

    p::first-letter {
      text-transform: capitalize;
    }
  }
}
.cs-relative {
  position: relative;
}

.cs-close {
  display: block;
  width: 100%;
  position: absolute;
  top: 10px;
  right: 16px;
  height: 30px;
  width: 30px;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
}

::v-deep .cs-btable * {
  color: #000;
  font-family: "Times New Roman", Times, serif;

  thead * {
    font-size: 16px;
    text-transform: none !important;
  }

  tbody {
    font-size: 16px;
  }
}

.cs-print {
  margin: 0 !important;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .cs-print {
    display: block !important;
    overflow: hidden;
    margin-top: 30px;

    .card-body {
      padding: 0 !important;
    }
  }

  .cs-print * {
    font-size: 20px;
  }

  .cs-container {
    margin-top: 30px;
  }
}
</style>